body .candidates .p-autocomplete .p-inputtext {
    width: 95%;
    border: none;
    font-size: 70%;
    margin-bottom: 2.2%;
    background-color: transparent;
}

body .candidates {
    position: absolute;
    bottom: 10%;
    left: 35%
}

body .candidates .number-candidates {
    position: absolute;
    right: 60%;
}

body .p-autocomplete {
    width: 100%
}