body .p-button.p-button-text-icon-left .p-button-text {
    position: absolute;
}

body .p-button.p-button-text-icon-left .p-button-subtext {
    position: absolute;
    font-size: 6px;
}

body .content-party .pi.p-c.p-button-icon-left img {
    width: 5em;
    height: 5em;
}

body .content-party .p-button {
    margin-right: 0.25em;
    height: 120px;
}

.p-button-icon-only .p-button-icon-left, .p-button-text-icon-left .p-button-icon-left, .p-button-text-icon-right .p-button-icon-right {
    margin-right: 100%
}

body .p-button .button-selected {
  width: 5em;
  height: 5em;
  left: 1%;
  position: absolute;
}