.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  min-height: 94.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid #000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    float: left;
    margin: 0;
}

body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link .p-menuitem-text {
    color: #333333;
}

body .p-tabmenu .p-tabmenu-nav {
    padding-right: 28%;
}

body .p-tabmenu.p-component.disabled {
    pointer-events: none;
    cursor: default;
}

body .p-button.p-button-secondary, body .p-buttonset.p-button-secondary > .p-button {
    color: #333333;
    border: 1px solid #f4f4f4;
}

body .p-button-secondary.confirm:hover {
    background-color: #546E7A !important;
}

.p-button.p-button-secondary:not(a):not(.p-disabled):hover, .p-buttonset.p-button-secondary > .p-button:not(a):not(.p-disabled):hover, .p-splitbutton.p-button-secondary > .p-button:not(a):not(.p-disabled):hover, .p-fileupload-choose.p-button-secondary:not(a):not(.p-disabled):hover {
	background-color: white !important;
	color: black !important;
}

.p-button-text-only {
	display: block;
}

body .p-button .pi {
    left: 1%;
    top: 17%;
    position: absolute;
}

.p-dialog {
  text-align: center;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: center;
}

body .p-dialog .p-dialog-footer button {
    margin: 0 0.5em 0 0;
    width: 100px;
    height: 30px;
}

body .p-button.confirm.p-button-text-icon-left .p-button-text {
    top: 0%;
}

body .p-rowgroup-footer {
    display: none;
}

body .p-panel {
    margin-right: 15%;
    width: 400%;
}