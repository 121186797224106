.modal-party .p-dialog-content {
	text-align: left;
	overflow: hidden;
}

.modal-party .p-dialog-footer .p-component.confirm {
    background-color: #007ad9 !important;
}

.modal-party .p-dialog-footer .p-button-secondary.confirm {
    background-color: #607D8B !important;
}

.modal-party .p-dialog-footer .p-button.p-button-text-icon-left .p-button-text {
	top: 0%;
    left: 0%;
    font-size: 90%;
}

.modal-party .p-button-text {
    left: 0px !important;
	font-size: 14px !important;
}

.modal-party .p-fileupload-choose {
    height: 32px !important;
    width: 150px !important;
	background-color: #007ad9 !important;
}

.modal-party .p-fileupload-choose .p-button-text {
	font-size: 10px !important;
}

.modal-party .p-fileupload-buttonbar button {
	display: none
}

.modal-party .p-fileupload .p-fileupload-buttonbar {
    background-color: white !important;
    padding: 0;
    border: none;
}

.modal-party .p-fileupload .p-fileupload-content {
    width: 95%;
	border: none;
}

.modal-party .p-fileupload .p-fileupload-row {
    display: grid;
}

.modal-party .p-fileupload .p-fileupload-content .p-progressbar {
    background-color: white;
}

.modal-party .p-fileupload .p-fileupload-content .p-button {
    height: 32px;
    width: 150px;
    background-color: #007ad9;
    right: 157px;
    top: 32px;
}

.modal-party .p-fileupload .p-button.p-button-text-icon-left .p-button-text {
    position: initial;
	padding: 0.99em 1em 0.429em 2.25em !important;
}

.modal-party .p-fileupload .p-fileupload-files {
    position: absolute;
    left: 144px;
    bottom: -127px;
}

.modal-party .p-fileupload .p-fileupload-content {
    height: 110px;
}

.modal-party .p-fileupload .p-fileupload-content .p-progressbar {
    width: 75%;
}

.modal-party .p-col {
    width: 0%;
}

.modal-party .p-fileupload-row {
	height: 280px;
}

.modal-party .p-fileupload-row>div:nth-child(2) {
    display: none;
}

.modal-party .p-fileupload-row>div:nth-child(3) {
    position: relative;    
    bottom: 200%;
    right: 80%;
}

.modal-party .p-fileupload-row>div:nth-child(4) {
    position: relative;
	bottom: 276%;
}

.modal-party .p-button:not(.p-button-secondary):not(a):not(.p-disabled):hover {
    background: #116fbf !important;
    color: #ffffff !important;
    border-color: #116fbf !important;
}

.p-fileupload-files img {
	position: relative;
    left: 112%;
	bottom: 30%;
	height: 150px;
}