.modal-voting-paper .p-dialog-content {
	text-align: left;
}

.modal-voting-paper .p-inputtext {
	width: 100%;
	max-height: 322px;
    max-width: 335px;
}

.modal-voting-paper .disabled {
    display: none;
}