body .little-nogroup .p-button, body .little-nogroup .p-buttonset.p-button-secondary > .p-button {
    font-size: 12px;
    margin-right: 0.1em;
    margin-bottom: 0.1em;
    min-width: 200px;
    background-color: white;
}

body .little-nogroup .p-button-icon-only .p-button-icon-left, body .little-nogroup .p-button-text-icon-left .p-button-icon-left {
    top: 24%;
    left: 0%;
}

body .little-nogroup .content-party .pi.p-c.p-button-icon-left img {
    width: 6em;
    height: 6em;
}

body .little-nogroup .content-party .p-button.admin-button {
	bottom: 60px;
}